import PropTypes from "prop-types";
import React, { useState } from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Grid from "../../UI/Grid";
import CustomImage from "../../UI/CustomImage";
import { Box } from "rebass/styled-components";
import Modal from "../../UI/Modal";

const Gallery = ({ data }) => {
  const images = data.images || [];
  const [isOpen, toggle] = useState(false);

  function handlOpenModal(open) {
    toggle(open);
  }
  if (images.length > 0) {
    return (
      <Wrapper data={data}>
        <Grid
          childWidth={[1, 1 / 2, 1 / 3, 1 / 4]}
          justifyContent={
            data.blockSettings.align === "center"
              ? "center"
              : data.blockSettings.align === "right"
              ? "flex-end"
              : ""
          }
        >
          {images.map((image, i) => {
            return (
              <Box key={"img-" + i} py={[2, 2, 5]}>
                <Box
                  onClick={() => handlOpenModal(i)}
                  sx={{ cursor: "pointer" }}
                >
                  <CustomImage img={image.image.publicURL} />
                </Box>
                <Modal
                  isOpen={i === isOpen}
                  handleClose={() => handlOpenModal(false)}
                >
                  <Box variant="container">
                    <CustomImage img={image.image.publicURL} display="block" mx="auto" />
                  </Box>
                </Modal>
              </Box>
            )
          })}
        </Grid>
      </Wrapper>
    );
  }
  return <></>;
};

Gallery.propTypes = {
  data: PropTypes.shape({
    blockSettings: PropTypes.shape({
      align: PropTypes.string
    }),
    images: PropTypes.array
  })
};
export default Gallery;
export const query = graphql`
  fragment GalleryBlock on MarkdownRemarkFrontmatterBlockscollection {
    images {
      alt
      image {
        publicURL
        childImageSharp {
          fluid(maxWidth: 300, quality: 64) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
